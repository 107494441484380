// const API_BASE_URL = 'http://172.20.10.12:8000'; // Replace with your Django server URL
// const API_BASE_URL = 'http://10.0.0.129:9000'; // Replace with your Django server URL
// const API_BASE_URL = 'https://ab10-2607-fea8-33a0-e000-2d43-14b-6fd1-9877.ngrok-free.app'; // Replace with your Django server URL
const API_BASE_URL = "https://api.nd-health.ca";
// const API_BASE_URL = "http://192.168.1.55:9000"; // Replace with your Django server URL
// const API_BASE_URL = "http://192.168.88.109:9000"; // Replace with your Django server URL
//const API_BASE_URL = "http://10.0.0.130:9000"; // Replace with your Django server URL
const API_BASE_PATH = `${API_BASE_URL}/api`;

export default API_BASE_PATH;
